import { ObjectType } from "../types/tableTypes";

const pricingLabelMappings = {
  borrowerFico: "Borrower FICO Pricing Bucket",
  // actualLeverage: "Actual Leverage - LTV/LTC/LTP",
  baseFicoLeverageBucket: "Base FICO / Leverage Adjusted Rate",
  prePaymentPenalty: "Rate Adj. - Prepayment Penalty",
  interestOnlyRate: "Rate Adj. - Interest Only",
  dscrRate: "Rate Adj. - DSCR",
  shortTermRentalRate: "Rate Adj. - Short Term Rental",
  cashOutRefinanceRate: "Rate Adj. - Cash Out Refinance",
  propertyTypeRate: "Rate Adj. - Property Type",
  loanBalanceRate: "Rate Adj. - Loan Balance",
  amortizationRate: "Rate Adj. - ARM",
  leveragePricingBucket: "Leverage Pricing Bucket",
  loanBalanceBucket: "Loan Balance Bucket",
};

export const labelFieldPricingMapping = [
  {
    label: "Final Price Adjustment",
    field: "totalPriceAdjustment",
    fieldType: "percentage",
    infoContent: `toorakProceeds.totalPriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Borrower FICO Pricing Bucket",
    field: "borrowerFico",
    fieldType: "string",
    infoContent: `toorakProceeds.borrowerFico`,
    convertToPercentage: false,
  },
  // {
  //   label: "Actual Leverage - LTV/LTC/LTP",
  //   field: "actualLeverage",
  //   fieldType: "percentage",
  //   infoContent: `toorakProceeds.actualLeverage`,
  //   convertToPercentage: true,
  // },
  {
    label: "Price Adj. - FICO",
    field: "ficoPriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.ficoPriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Pre-payment Penalty Term",
    field: "penaltyTermPriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.penaltyTermPriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Loan Exceptions",
    field: "loanExceptionsPriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.loanExceptionsPriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - DSCR",
    field: "dscrPriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.dscrPriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Loan Term",
    field: "shortTermRentalRate",
    fieldType: "string",
    infoContent: `loanPricing.shortTermRentalRate`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Loan Purpose",
    field: "loanPurposePriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.loanPurposePriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Property Type",
    field: "propertyTypePriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.propertyTypePriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Loan Balance",
    field: "loanBalancePriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.loanBalancePriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Cross Collateralized",
    field: "crossCollaterizedAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.crossCollaterizedAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Amortization",
    field: "amortizationPriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.amortizationPriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Leverage Pricing Bucket",
    field: "leveragePricingBucket",
    fieldType: "string",
    infoContent: `loanPricing.leveragePricingBucket`,
    convertToPercentage: false,
  },
  {
    label: "Loan Balance Bucket",
    field: "loanBalanceBucket",
    fieldType: "string",
    infoContent: `loanPricing.loanBalanceBucket`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. - Tier state",
    field: "propertyLocationPriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.loanBalanceBucket`,
    convertToPercentage: false,
  },
  {
    label: "FC/SS/DIL/BK7 36 - 47mo",
    field: "creditEventPriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.creditEventPriceAdjustment`,
    convertToPercentage: false,
  },
  {
    label: "Price Adj. FN/NPRA",
    field: "loanUserTypePriceAdjustment",
    fieldType: "string",
    infoContent: `loanPricing.loanUserTypePriceAdjustment`,
    convertToPercentage: false,
  },
];

export const labelFieldBridgePriceMapping = [
  {
    label: "Location Adjustments",
    field: "locationAdjustment",
    fieldType: "percentage",
    infoContent: `toorakInterests.locationAdjustment`,
    isVisible: (isGroundUp: boolean) => true,
  },
  {
    label: "Loan Purpose Adjustments",
    field: "loanPurposeAdjustment",
    fieldType: "percentage",
    infoContent: `toorakInterests.loanPurposeAdjustment`,
    isVisible: (isGroundUp: boolean) => true,
  },
  {
    label: "Ground Up Adjustments",
    field: "groundUpAdjustment",
    fieldType: "percentage",
    infoContent: `toorakInterests.groundUpAdjustment`,
    isVisible: (isGroundUp: boolean) => true,
  },
  {
    label: "Rehab Type Adjustments",
    field: "rehabTypeAdjustment",
    fieldType: "percentage",
    infoContent: `toorakInterests.rehabTypeAdjustment`,
    isVisible: (isGroundUp: boolean) => !isGroundUp,
  },
  {
    label: "Leverage Rate Adjustments",
    field: "leverageRateAdjustment",
    fieldType: "percentage",
    infoContent: `toorakInterests.leverageRateAdjustment`,
    isVisible: (isGroundUp: boolean) => true,
  },
];

export enum SortOrder {
  ascending = "ASC",
  descending = "DESC",
}
export const labelFieldAdjustmentMapping: ObjectType = {
  medianFico: "Median FICO",
  foreignNational:"Foreign National",
  medianHomePriceDecline: "Median Home Price Decline",
  propertyValueVSMedianZipCodeValue: "Property Value vs Median Zip Code Value",
  equityPledge: "Equity Pledge",
  brookylnQueensBronx: "Brooky ln Queens Bronx",
  manhatten: "Manhattan",
  sanFransiscoPeninsula:"SF Peninsula",
  newarkandAdjSuburbsPaterson: "Newark and Adj Suburbs, Paterson",
  maxLoanSize: "Max Loan Size",
  mixedUseAdjustment: "Mixed Use",
  valuePerUnitAdjustment: "ARV >= $40 K and <= $50 K LTV",
  manhattenAdjustment:"Property in Manhattan",
  sanFranPatersonNewarkAdjustment: "Property in San Francisco, Paterson, Newark",
  recourseAdjustment: "Non-Recourse",
  baltimoreAdjustment:  "Baltimore",
  stabilizedVacancy: "Stabilized Refinance Vacancy",
  ficoBetween650and680: "FICO Between 650 and 680",
  hpaDeclineBetweenTwoToFivePercent: "HPA decline between 2% - 5%",
  loanSize: "Loan Size",
  noPlansAndPermitsApproval: "No Plans/Permits Approvals",
  totalMarketDataDeductions: "Total Market Data Deductions",
  medianHomeValueMultiplier: "Median Home Value Multiplier",
  condoEligibility: "Condo Eligibility Adjustment",
  loanBalance75KandDscr2x: "Loan Balance & DSCR",
  zipCodeDensity: "Zip Code Density",
  dscrAdjustment: "DSCR Adjustment",
  occupancyAdjustment: "Occupancy Adjustment",
  totalAdjustments: "Total Adjustments",
  loanPurposeAdjustment: "Cash Out",
  decliningMarketsAdjustment: "Declining Markets Adjustment",
  propertyLocationAdjustment: "Property Location Adjustment",
  costarVacancyRateAdjustment: "CoStar Vacancy Rate",
  costarPropertyRentAdjustment: "Property rent vs market adjustment",
};

export const finalPricingTables: ObjectType = {
  "basePrice": "basePrice",
  // "actualInterestRate": "actualInterestRate", 
  "noteRate": "noteRate", 
  "prepaymentPenaltyMaxPrice": "pppMaxPrice",
  "stateRestrictionMaxPrice": "statepppRestrictionMaxPrice",
  "maxAllowedToorakPrice": "maxAllowedToorakPrice",
}